/*@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'; */
/*@import '~@okta/okta-signin-widget/dist/css/okta-theme.css';*/
@import "../src/assets/css/mainstyle.css";
@import "../src/assets/css/google-font.css";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
/* 
@font-face {
  font-family: 'SegoeUI';
  src: url('/assets/fonts/SegoeUI.woff') format('woff'),
  url('/assets/fonts/segoeui.ttf') format('truetype'),
  url('/assets/fonts/SegoeUI.svg#SegoeUI') format('svg');
  src: url('/assets/fonts/segoeui.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* SegoeUI Light */
/* 
@font-face {
  font-family: 'SegoeUI-Light';
  src: url('/assets/fonts/SegoeUI-Light.woff') format('woff'),
  url('/assets/fonts/SegoeUI-Light.ttf') format('truetype'),
  url('/assets/fonts/SegoeUI-Light.svg#SegoeUI-Light') format('svg');
  src: url('/assets/fonts/segoeuil.eot');
  src: url('/assets/fonts/segoeuil.woff2') format('woff2'),
  url('/assets/fonts/segoeuil.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
} */


/* SegoeUI Bold */

/* @font-face {
  font-family: 'SegoeUI-Bold';
  src: url('/assets/fonts/SegoeUI-Bold.woff') format('woff'),
  url('/assets/fonts/SegoeUI-Bold.ttf') format('truetype'),
  url('/assets/fonts/SegoeUI-Bold.svg#SegoeUI-Bold') format('svg');
  src: url('/assets/fonts/segoeuib.eot');
  src: url('/assets/fonts/segoeuib.woff2') format('woff2'),
  url('/assets/fonts/segoeuib.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
} */

/* SegoeUI Semibold */

/* @font-face {
  font-family: 'SegoeUI-Semibold';
  src: url('/assets/fonts/SegoeUI-Semibold.woff') format('woff'),
  url('/assets/fonts/SegoeUI-Semibold.ttf') format('truetype'),
  url('/assets/fonts/SegoeUI-Semibold.svg#SegoeUI-Semibold') format('svg');
  src: url('/assets/fonts/seguisb.eot');
  src: url('/assets/fonts/seguisb.woff2') format('woff2'),
  url('/assets/fonts/seguisb.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
} */


/* --------------------------------
Font family SegeoUI End
-------------------------------- */


/* --------------------------------
MDL2Assets Start
-------------------------------- */
/* @font-face {
  font-family: segoeMDL2Assests ;
  src: url('/assets/fonts/SegoeMDL2Assets.woff') format('woff'),
  url('/assets/fonts/SegoeMDL2Assets.ttf') format('truetype'),
  url('/assets/fonts/SegoeMDL2Assets.svg#SegoeMDL2Assets') format('svg');
  src: url('/assets/fonts/segmdl2.eot');
  src: url('/assets/fonts/segmdl2.woff2') format('woff2'),
  url('/assets/fonts/segmdl2.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
} */

/* --------------------------------
MDL2Assets End
-------------------------------- */
body {
  font-family: 'SegoeUI', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 15px;
  background-color: #f8f8f8 !important;
}

.bg-blue .btn.disabled, .bg-blue .btn:disabled {
  opacity: .65;
  cursor: default;
}

.custom-error {
  color: red;
  font-family: 'SegoeUI', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  padding: 2px 0;
}

.btn-success.disabled, .btn-success:disabled, .resetbtn {
  width: 80px;
  margin-right: 10px;
}

.arrowBtn-section .fa-arrow-circle-right:before {
  content: "\f0a9";
  color: #0078d4;
}

.btn.active {
  box-shadow: none;
}

.table td, .table th {
  padding: 10px 15px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table_morecondensed>thead>tr>th, 
.table_morecondensed>tbody>tr>th, 
.table_morecondensed>tfoot>tr>th, 
.table_morecondensed>thead>tr>td, 
.table_morecondensed>tbody>tr>td, 
.table_morecondensed>tfoot>tr>td{ padding: 5px; }

.customer-tab {
  margin-bottom: 50px;

}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
.community-events-container  {
  position: relative;
}
.community-events-container table {
  border: 1px solid #ccc;
  border-top: none;
}
.community-events-container caption {
  caption-side: top;
  text-align: left;
  position: absolute;
  top: 36px;
  background-color: #fff;
  left: 30px;
  padding: 0;
}
.community-events-container .mt-3.mb-2 {
  float: right;
  width: 40px;
  background-color: #0067b8;
  border: none;
  color: #fff;
}
.modal-footer .btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.modal-footer .btn-primary {
  background-color: #3c8dbc;
  border-color: #367fa9;
  min-width: 80px;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
  cursor: default !important;
}

.modal-footer .btn-primary:hover {
  background-color: #005da6 !important;
  color: #fff;
}

.modal-footer .btn {
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
}

.modal-footer .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.row-header {
  background: #f8f8f8;
}

.table .icon-btn-cl {
  padding: 0px 0px;
  margin-bottom: 0;
  /* width: 20px; */
  /* height: 20px; */
  margin-left: 15px;
  color: #000;
  font-size: 16px;
  background: transparent !important;
}

.searchbar_section {
  margin: 15px 0 10px;

}

.box-title {
  display: inline-block;
  font-size: 15px !important;
  margin: 0;
  line-height: 1;
  margin: 20px 15px 0;
  font-family:Arial, Helvetica, sans-serif;
}


table td th {
  width: 100px !important;
}

.tab-heading {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
}

h5 {
  padding-left: 5px;
}

.bg-blue {
  background-color: #0067b8 !important;
  color: #fff;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25);
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  margin-top: 20px;
  width: 100%;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  margin-bottom: 22px;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  min-height: 400px;
}

.bg-blue:hover {
  background-color: #005da6 !important;
  color: #fff;
}

.btn-pdt {
  margin-right: 10px;
}

.bg-gray {
  background-color: #0067b8 !important;
  color: #fff;
}

.bg-gray:hover {
  background-color: #005da6 !important;
  color: #fff;
}

.btn.btn-flat {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-width: 1px;
  font-size: 14px;
}

.btn:active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #3c8dbc;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25);
}

.form-control:focus {
  border-color: #3c8dbc;
  box-shadow: none;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.search {
  width: 50%;
  height: 90%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
  font-size: 13px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
  font-size: 13px;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc;
  font-size: 13px;
}

.table th {
  font-family: 'SegoeUI-Semibold', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.log-out-btn span {
  font-family: 'SegoeUI', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.content-header > h1 {
  font-family: 'SegoeUI-Semibold', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25) !important;
}

.mat-tooltip {
  font-size: 12px !important;
  font-weight: bold !important;
  background-color: #00c0ef;
}

.sidebar-menu .active {
  border-left-color: #0078d4 !important;
  background: #f8f8f8 !important;
  color: #000 !important;
}

.skin-blue .sidebar a:hover {
  border-left-color: #0078d4 !important;
  background: #1e282c !important;
  color: #fff !important;
}

.skin-blue .sidebar a:hover .fa {
  color: #fff !important;
}

.sidebar-menu a.active .fa {
  color: #0078d4;
}

.label-required {
  color: red;
  font-weight: bold;
  font-size: 14px;
  margin-left: 2px;
}

.paddata {
  padding: 12px !important;
}

.mat-drawer-content {
  min-height: 580px;
  padding-bottom: 50px;
  padding-top: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.mat-drawer.mat-drawer-side {
  z-index: 2;
  position: fixed;
  padding-top: 50px;
}

.main-header {
  position: fixed;
  width: 100%;
  top: 0;
}
.main-header .navbar {
  height: 50px;
}
.main-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

#okta-login-container {
  /* margin: 10% auto 0;
   max-width: 400px;*/
  border: 0px solid silver;
  padding: 0px;
  box-shadow: 5px 5px 5px 0 silver;

}

.logoutbtn {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 1111111;
  color: #fff;
}

.cursor {
  cursor: pointer;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  z-index: 999999;
  background-color: rgb(0, 0, 0, 0.4);
}

#okta-login-container input {
  margin-bottom: 5px;
  width: 100%;
  padding: 5px;
}

#okta-login-container input[type=checkbox] {
  width: 25px;
}

/*from all-skins.min.css*/
.skin-blue .main-header .navbar {
  background-color: #000;
}

.skin-blue .form-control {

  border: 1px solid #d2d6de;
  border-radius: 0rem;

}

.skin-blue .main-header .navbar .nav > li > a {
  color: #fff
}

.skin-blue .main-header .navbar .nav > li > a:hover, .skin-blue .main-header .navbar .nav > li > a:active, .skin-blue .main-header .navbar .nav > li > a:focus, .skin-blue .main-header .navbar .nav .open > a, .skin-blue .main-header .navbar .nav .open > a:hover, .skin-blue .main-header .navbar .nav .open > a:focus, .skin-blue .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6
}

.skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1)
}

.skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: #367fa9
}

@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1)
  }

  .skin-blue .main-header .navbar .dropdown-menu li a {
    color: #fff
  }

  .skin-blue .main-header .navbar .dropdown-menu li a:hover {
    background: #367fa9
  }
}

.skin-blue .main-header .logo {
  background-color: #0072c6;
  color: #fff;
  border-bottom: 0 solid transparent
}

.skin-blue .table-hover tbody tr:hover {
  background-color: #f9f9f9;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}

#header-main-header .cis_logo {
  font-size: 28px;
  font-family: 'SegoeUI-Semibold', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.skin-blue .main-header .logo:hover {
  background-color: #0072c6;
}

.skin-blue .main-header li.user-header {
  background-color: #3c8dbc
}

.skin-blue .content-header {
  background: transparent
}

.skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side {
  background-color: #000;
}

.skin-blue .user-panel > .info, .skin-blue .user-panel > .info > a {
  color: #fff
}

.skin-blue .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226
}

.skin-blue .sidebar-menu > li > a {
  border-left: 3px solid transparent
}

.skin-blue .sidebar-menu > li:hover > a, .skin-blue .sidebar-menu > li.active > a {
  color: #fff;
  background: #000;
}

.skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41
}

.skin-blue .form-control {
  font-size: 14px;

}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #3c8dbc;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(60, 141, 188, .25) !important;
}

.mat-drawer-container {
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, .87)
}

.skin-blue .sidebar a {
  color: #b8c7ce
}

.skin-blue .sidebar a:hover {
  text-decoration: none
}

.skin-blue .treeview-menu > li > a {
  color: #8aa4af
}

.skin-blue .treeview-menu > li.active > a, .skin-blue .treeview-menu > li > a:hover {
  color: #fff
}

.skin-blue .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px
}

.skin-blue .sidebar-form input[type="text"], .skin-blue .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px
}

.skin-blue .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px
}

.skin-blue .sidebar-form input[type="text"]:focus, .skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666
}

.skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff
}

.skin-blue .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0
}

.skin-blue.layout-top-nav .main-header > .logo {
  background-color: #3c8dbc;
  color: #fff;
  border-bottom: 0 solid transparent
}

.skin-blue.layout-top-nav .main-header > .logo:hover {
  background-color: #3b8ab8
}

.skin-blue-light .main-header .navbar {
  background-color: #3c8dbc
}

.skin-blue-light .main-header .navbar .nav > li > a {
  color: #fff
}

.skin-blue-light .main-header .navbar .nav > li > a:hover, .skin-blue-light .main-header .navbar .nav > li > a:active, .skin-blue-light .main-header .navbar .nav > li > a:focus, .skin-blue-light .main-header .navbar .nav .open > a, .skin-blue-light .main-header .navbar .nav .open > a:hover, .skin-blue-light .main-header .navbar .nav .open > a:focus, .skin-blue-light .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6
}

.skin-blue-light .main-header .navbar .sidebar-toggle {
  color: #fff
}

.skin-blue-light .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1)
}

.skin-blue-light .main-header .navbar .sidebar-toggle {
  color: #fff
}

.skin-blue-light .main-header .navbar .sidebar-toggle:hover {
  background-color: #367fa9
}

@media (max-width: 767px) {
  .skin-blue-light .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1)
  }

  .skin-blue-light .main-header .navbar .dropdown-menu li a {
    color: #fff
  }

  .skin-blue-light .main-header .navbar .dropdown-menu li a:hover {
    background: #367fa9
  }
}

.skin-blue-light .main-header .logo {
  background-color: #3c8dbc;
  color: #fff;
  border-bottom: 0 solid transparent
}

.skin-blue-light .main-header .logo:hover {
  background-color: #3b8ab8
}

.skin-blue-light .main-header li.user-header {
  background-color: #3c8dbc
}

.skin-blue-light .content-header {
  background: transparent
}

.skin-blue-light .wrapper, .skin-blue-light .main-sidebar, .skin-blue-light .left-side {
  background-color: #000;
}

.skin-blue-light .content-wrapper, .skin-blue-light .main-footer {
  border-left: 1px solid #d2d6de
}

.skin-blue-light .user-panel > .info, .skin-blue-light .user-panel > .info > a {
  color: #444
}

.skin-blue-light .sidebar-menu > li {
  -webkit-transition: border-left-color .3s ease;
  -o-transition: border-left-color .3s ease;
  transition: border-left-color .3s ease
}

.skin-blue-light .sidebar-menu > li.header {
  color: #848484;
  background: #f9fafc
}

.skin-blue-light .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  font-weight: 600
}

.skin-blue-light .sidebar-menu > li:hover > a, .skin-blue-light .sidebar-menu > li.active > a {
  color: #000;
  background: #f4f4f5
}

.skin-blue-light .sidebar-menu > li.active {
  border-left-color: #3c8dbc
}

.skin-blue-light .sidebar-menu > li.active > a {
  font-weight: 600
}

.skin-blue-light .sidebar-menu > li > .treeview-menu {
  background: #f4f4f5
}

.skin-blue-light .sidebar a {
  color: #444
}

.skin-blue-light .sidebar a:hover {
  text-decoration: none
}

.skin-blue-light .treeview-menu > li > a {
  color: #777
}

.skin-blue-light .treeview-menu > li.active > a, .skin-blue-light .treeview-menu > li > a:hover {
  color: #000
}

.skin-blue-light .treeview-menu > li.active > a {
  font-weight: 600
}

.skin-blue-light .sidebar-form {
  border-radius: 3px;
  border: 1px solid #d2d6de;
  margin: 10px 10px
}

.skin-blue-light .sidebar-form input[type="text"], .skin-blue-light .sidebar-form .btn {
  box-shadow: none;
  background-color: #fff;
  border: 1px solid transparent;
  height: 35px
}

.skin-blue-light .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px
}

.skin-blue-light .sidebar-form input[type="text"]:focus, .skin-blue-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666
}

.skin-blue-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff
}

.skin-blue-light .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0
}

@media (min-width: 768px) {
  .skin-blue-light.sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    border-left: 1px solid #d2d6de
  }
}

.skin-blue-light .main-footer {
  border-top-color: #d2d6de
}

.skin-blue.layout-top-nav .main-header > .logo {
  background-color: #3c8dbc;
  color: #fff;
  border-bottom: 0 solid transparent
}

.skin-blue.layout-top-nav .main-header > .logo:hover {
  background-color: #3b8ab8
}

/*css for models*/

.custom-modal {
  display: none;
  position: fixed;
  z-index: 999999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

}

.custom-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 15px 0px 20px 20px;
  border: 1px solid #888;
  width: 40%;
  overflow: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  border-radius: 8px;
}

.custom-close {
  color: #dc1e1e;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: relative;
  bottom: 20px;
  right: 10px;
}

.custom-close:hover,
.custom-close:focus {
  color: #ff0000;
  text-decoration: none;
  cursor: pointer;
}

.delete-alert-div {
  padding-top: 3em;
}

.delete-alert-div {
  margin: auto;
  width: 30%;
  padding: 25px 0px 0px 0px;
}

.delete-alert-div button {
  border-radius: 5px;
  font-size: 14px;
  width: 4em;
}

#btn-not-confirm-del-btn {
  margin-left: 15px;
}
.display-message {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

/*button to close pages*/
.modal-close-button {
  margin-left: 45%;
  margin-top: 3.5em;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #28a745 !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #fafafa !important;
}

.mat-slide-toggle-bar {
  background-color: red !important;
}

.tree-view ul li {
  list-style-type: none;
}

.hand {
  cursor: pointer;
}

.pad-tree-view {
  padding: 0px;
}

.board-icons i.fa.fa-pencil.fa-fw {
  color: #26ff00;
}

.board-icons i.fa.fa-book.fa-fw,
.board-icons i.fa.fa-trophy {
  color: red;
}

.board-icons i.fa.fa-comments {
  color: #3fc0c0;
}

.board-icons i.fa.fa-group {
  color: #00804c;
  font-size: 12px;
}

.board-icons i.fa.fa-question {
  color: #132be5;
}

.board-icons i.hand.fa.fa-folder-open, i.hand.fa.fa-folder {
  color: #c1cc1cb8;
}


/*checkBox styles*/
/* The chkBoxContainer */
.chkBoxContainer {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #8a8a8a;
}

/* On mouse-over, add a grey background color */
/*.chkBoxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}*/

/* When the checkbox is checked, add a blue background */
.chkBoxContainer input:checked ~ .checkmark {
  background-color: #2196F3;
  border: 1px solid #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chkBoxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chkBoxContainer .checkmark:after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.input-group .input-group-addon {
  border-radius: 0;
  border-color: #d2d6de;
  background-color: #fff;
}

.searchbar_section {
  position: relative;
}

.searchbar_section .input-group-addon {
  position: absolute;
  right: 10px;
  top: 5px;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

/*css for search-bar*/
.searchbar_section {
  margin: 15px 0;
  display: inline-block;
  float: right;
}

.customer-label {
  padding: 7.5px 15px;
}

input.form-control.search {
  width: 250px;
  height: 32px;
  padding-right: 30px;
}

.header-title-font {
  font-size: 16px;
}

button#auto-focus-close:focus {
  outline: 0px;
}

.btn .fa fa-edit, input.btn, .btn.bg-blue {
  color: white;
}

.btn .fa fa-edit:hover, fa-times:hover input.btn:hover, .btn.bg-blue :hover {
  color: white !important;
}
.btn.bg-blue:hover{
  color: white !important;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}

.wc-date-row{
    display: none;
  }
  .wc-day-row {
  display: none;
  }
  .wc-date-row {
    display: none !important;
  }
  .wc-my-sec {
    display: none !important;
  }
  .time-view {
    position: initial !important;
  }
  .wc-date-container {
    width: 200px !important;
  }
.mat-dialog-container {
  padding: 0px !important;
}
.mat-toolbar.mat-primary {
  background: #1e72c6;
  color: #fff;
}
.mat-drawer-content .mat-sidenav-content {
  margin-left: 300px;
}
.container {
  padding-left: 20px !important;
}