.skin-blue .sidebar-menu > li:hover > a, .skin-blue .sidebar-menu > li.active > html, body {
	height:100%;
}


.nav-tabs>li {
  border-top: 3px solid transparent;
  margin-bottom: -2px;
  margin-right: 50px;
}
.nav-tabs>li.active {
  border-top-color: #3c8dbc;
}
.nav-tabs>li.active>a {
  border-top-color: transparent;
  border-left-color: #f4f4f4;
  border-right-color: #f4f4f4;
}
.nav-tabs>li.active>a, .nav-tabs>li.active:hover>a {
  background-color: #fff;
  color: #444;
}
.nav-tabs>li>a, .nav-tabs>li>a:hover {
  background: transparent;
  margin: 0;
}
body {
	font-family:'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-weight:400;
	overflow-x:hidden;
	overflow-y:auto;
  font-size: 14px !important;
  background-color: blue;
}
.wrapper {
	height:100%;
	position:relative;
	overflow-x:hidden;
	overflow-y:auto
}
.wrapper:before, .wrapper:after {
	content:" ";
	display:table
}
.wrapper:after {
	clear:both
}
.nav-padding{
  padding:10px 15px;

}
.headerrow{
  width:100%;
  display: block;
  z-index: 111111;
  position:relative;
  float: left;
  border-bottom: 1px solid #f4f4f4;


}
.nav-tabs {
  border-bottom: 0px solid #dee2e6 !important;
}
ul.nav.nav-tabs.pull-right.ui-sortable-handle {
  float: right !important;
  position:relative;
}
.ui-sortable-handle li a{
  padding:5px 20px;
  line-height: 32px;
float:left;
}
.layout-boxed .wrapper {
	max-width:1250px;
	margin:0 auto;
	min-height:100%;
	box-shadow:0 0 8px rgba(0, 0, 0, 0.5);
	position:relative
}
/*.layout-boxed {*/
	/*background:url('../img/boxed-bg.jpg') repeat fixed*/
/*}*/
.content-wrapper, .right-side, .main-footer {
-webkit-transition:-webkit-transform .3s ease-in-out, margin .3s ease-in-out;
-moz-transition:-moz-transform .3s ease-in-out, margin .3s ease-in-out;
-o-transition:-o-transform .3s ease-in-out, margin .3s ease-in-out;
transition:transform .3s ease-in-out, margin .3s ease-in-out;
	margin-left:0px;
	z-index:820
}
.layout-top-nav .content-wrapper, .layout-top-nav .right-side, .layout-top-nav .main-footer {
	margin-left:0
}
@media (max-width:767px) {
.content-wrapper, .right-side, .main-footer {
margin-left:0
}
}
@media (min-width:768px) {
.sidebar-collapse .content-wrapper, .sidebar-collapse .right-side, .sidebar-collapse .main-footer {
margin-left:0
}
}
@media (max-width:767px) {
.sidebar-open .content-wrapper, .sidebar-open .right-side, .sidebar-open .main-footer {
-webkit-transform:translate(230px, 0);
-ms-transform:translate(230px, 0);
-o-transform:translate(230px, 0);
transform:translate(230px, 0)
}
}
.content-wrapper, .right-side {
	min-height:450px;
	background-color:#ecf0f5;
	z-index:800
}
.main-footer {
	background:#fff;
	padding:15px;
	color:#444;
	border-top:1px solid #d2d6de;
  font-family: 'SegoeUI', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}
.fixed .main-header, .fixed .main-sidebar, .fixed .left-side {
	position:fixed
}
.fixed .main-header {
	top:0;
	right:0;
	left:0
}
.fixed .content-wrapper, .fixed .right-side {
	padding-top:50px
}
@media (max-width:767px) {
.fixed .content-wrapper, .fixed .right-side {
padding-top:0px
}
}
.fixed.layout-boxed .wrapper {
	max-width:100%
}
body.hold-transition .content-wrapper, body.hold-transition .right-side, body.hold-transition .main-footer, body.hold-transition .main-sidebar, body.hold-transition .left-side, body.hold-transition .main-header .navbar, body.hold-transition .main-header .logo {
	-webkit-transition:none;
	-o-transition:none;
	transition:none
}
.content {
	min-height:250px;
	padding:15px;
	margin-right:auto;
	margin-left:auto;
	padding-left:15px;
	padding-right:15px
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family:'Source Sans Pro', sans-serif
}
a {
	color:#3c8dbc
}
a:hover, a:active, a:focus {
	outline:none;
	text-decoration:none;
	color:#72afd2
}
.page-header {
	margin:10px 0 20px 0;
	font-size:22px
}
.page-header>small {
	color:#666;
	display:block;
	margin-top:5px
}
.main-header {
	position:relative;
	max-height:100px;
	z-index:1030
}
.main-header .navbar {
-webkit-transition:margin-left .3s ease-in-out;
-o-transition:margin-left .3s ease-in-out;
transition:margin-left .3s ease-in-out;
	margin-bottom:0;
	margin-left:0px;
	border:none;
	min-height:50px;
	border-radius:0
}
.layout-top-nav .main-header .navbar {
	margin-left:0
}
.main-header #navbar-search-input.form-control {
	background:rgba(255, 255, 255, 0.2);
	border-color:transparent
}
.main-header #navbar-search-input.form-control:focus, .main-header #navbar-search-input.form-control:active {
	border-color:rgba(0, 0, 0, 0.1);
	background:rgba(255, 255, 255, 0.9)
}
.main-header #navbar-search-input.form-control::-moz-placeholder {
color:#ccc;
opacity:1
}
.main-header #navbar-search-input.form-control:-ms-input-placeholder {
color:#ccc
}
.main-header #navbar-search-input.form-control::-webkit-input-placeholder {
color:#ccc
}
.main-header .navbar-custom-menu, .main-header .navbar-right {
	float:right
}
@media (max-width:991px) {
.main-header .navbar-custom-menu a, .main-header .navbar-right a {
color:inherit;
background:transparent
}
}
@media (max-width:767px) {
.main-header .navbar-right {
float:none
}

.main-header .navbar-right>li {
color:inherit;
border:0
}
}
.main-header .sidebar-toggle {
	float:left;
	background-color:transparent;
	background-image:none;
  border:0px;
	padding:5px 15px;
	font-family:fontAwesome
}
.main-header .sidebar-toggle:before {
	content:"\f0c9"
}
.main-header .sidebar-toggle:hover {
	color:#fff
}
.main-header .sidebar-toggle:focus, .main-header .sidebar-toggle:active {
	background:transparent
}
.main-header .sidebar-toggle .icon-bar {
	display:none
}
.main-header .navbar .nav>li.user>a>.fa, .main-header .navbar .nav>li.user>a>.glyphicon, .main-header .navbar .nav>li.user>a>.ion {
	margin-right:5px
}
.main-header .navbar .nav>li>a>.label {
	position:absolute;
	top:9px;
	right:7px;
	text-align:center;
	font-size:9px;
	padding:2px 3px;
	line-height:.9
}
.main-header .logo {
-webkit-transition:width .3s ease-in-out;
-o-transition:width .3s ease-in-out;
transition:width .3s ease-in-out;
	display:block;
	float:left;
	height:50px;
	font-size:20px;
	text-align:center;
	width:230px;
	font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
	padding:0 15px;
	font-weight:300;
	overflow:hidden
}
.main-header .logo .logo-lg {
	display:block
}
.main-header .logo .logo-mini {
	display:none
}
.main-header .navbar-brand {
	color:#fff
}
.content-header {
	position:relative;
	padding:15px 15px 0 0px
}
.content-header>h1 {
	margin:0;
	font-size:24px
}
.content-header>h1>small {
	font-size:15px;
	display:inline-block;
	padding-left:4px;
	font-weight:300
}
.content-header>.breadcrumb {
	float:right;
	background:transparent;
	margin-top:0;
	margin-bottom:0;
	font-size:12px;
	padding:7px 5px;
	position:absolute;
	top:15px;
	right:10px;
	border-radius:2px
}
.content-header>.breadcrumb>li>a {
	color:#444;
	text-decoration:none;
	display:inline-block
}
.content-header>.breadcrumb>li>a>.fa, .content-header>.breadcrumb>li>a>.glyphicon, .content-header>.breadcrumb>li>a>.ion {
	margin-right:5px
}
.content-header>.breadcrumb>li+li:before {
	content:'>\00a0'
}
@media (max-width:991px) {
.content-header>.breadcrumb {
position:relative;
margin-top:5px;
top:0;
right:0;
float:none;
background:#d2d6de;
padding-left:10px
}
.content-header>.breadcrumb li:before {
color:#97a0b3
}
}
.navbar-toggle {
	color:#fff;
	border:0;
	margin:0;
	padding:15px 15px
}
@media (max-width:991px) {
.navbar-custom-menu .navbar-nav>li {
float:left
}
.navbar-custom-menu .navbar-nav {
margin:0;
float:left
}
.navbar-custom-menu .navbar-nav>li>a {
padding-top:15px;
padding-bottom:15px;
line-height:20px
}
}
@media (max-width:767px) {
.main-header {
position:relative
}
.main-header .logo, .main-header .navbar {
width:100%;
float:none
}
.main-header .navbar {
margin:0
}
.main-header .navbar-custom-menu {
float:right
}
}
@media (max-width:991px) {
.navbar-collapse.pull-left {
float:none !important
}
.navbar-collapse.pull-left+.navbar-custom-menu {
display:block;
position:absolute;
top:0;
right:40px
}
}
.main-sidebar, .left-side {
	position:relative;
	top:0;
	left:0;
	padding-top:0px;
	min-height:100%;
	width:230px;
	z-index:810;
-webkit-transition:-webkit-transform .3s ease-in-out, width .3s ease-in-out;
-moz-transition:-moz-transform .3s ease-in-out, width .3s ease-in-out;
-o-transition:-o-transform .3s ease-in-out, width .3s ease-in-out;
transition:transform .3s ease-in-out, width .3s ease-in-out
}
@media (max-width:767px) {
.main-sidebar, .left-side {
padding-top:0px
}
}

@media (max-width:767px) {
.sidebar-open .main-sidebar, .sidebar-open .left-side {
-webkit-transform:translate(0, 0);
-ms-transform:translate(0, 0);
-o-transform:translate(0, 0);
transform:translate(0, 0)
}
}
.sidebar {
	padding-bottom:10px
}
.sidebar-form input:focus {
	border-color:transparent
}
.sidebar-menu {
	list-style:none;
	margin:0;
	padding:0
}
.sidebar-menu>li {
	position:relative;
	margin:0;
	padding:0
}
.sidebar-menu>li>a {
	padding:12px 5px 12px 15px;
	display:block
}
.sidebar-menu>li>a>.fa, .sidebar-menu>li>a>.glyphicon, .sidebar-menu>li>a>.ion {
	width:20px;
  margin-right: 5px;
}
.sidebar-menu>li .label, .sidebar-menu>li .badge {
	margin-right:5px
}
.sidebar-menu>li .badge {
	margin-top:3px
}
.sidebar-menu li.header {
	padding:10px 25px 10px 15px;
	font-size:12px
}
.sidebar-menu li>a>.fa-angle-left, .sidebar-menu li>a>.pull-right-container>.fa-angle-left {
	width:auto;
	height:auto;
	padding:0;
	margin-right:10px
}
.sidebar-menu li>a>.fa-angle-left {
	position:absolute;
	top:50%;
	right:10px;
	margin-top:-8px
}
.sidebar-menu li.active>a>.fa-angle-left, .sidebar-menu li.active>a>.pull-right-container>.fa-angle-left {
	-webkit-transform:rotate(-90deg);
	-ms-transform:rotate(-90deg);
	-o-transform:rotate(-90deg);
	transform:rotate(-90deg)
}
.sidebar-menu li.active>.treeview-menu {
	display:block
}
.sidebar-menu .treeview-menu {
	display:none;
	list-style:none;
	padding:0;
	margin:0;
	padding-left:5px
}
.sidebar-menu .treeview-menu .treeview-menu {
	padding-left:20px
}
.sidebar-menu .treeview-menu>li {
	margin:0
}
.sidebar-menu .treeview-menu>li>a {
	padding:5px 5px 5px 15px;
	display:block;
	font-size:14px
}
.sidebar-menu .treeview-menu>li>a>.fa, .sidebar-menu .treeview-menu>li>a>.glyphicon, .sidebar-menu .treeview-menu>li>a>.ion {
	width:20px
}
.sidebar-menu .treeview-menu>li>a>.pull-right-container>.fa-angle-left, .sidebar-menu .treeview-menu>li>a>.pull-right-container>.fa-angle-down, .sidebar-menu .treeview-menu>li>a>.fa-angle-left, .sidebar-menu .treeview-menu>li>a>.fa-angle-down {
	width:auto
}
@media (min-width:768px) {
.sidebar-mini.sidebar-collapse .content-wrapper, .sidebar-mini.sidebar-collapse .right-side, .sidebar-mini.sidebar-collapse .main-footer {
margin-left:50px !important;
z-index:840

}
.sidebar-mini.sidebar-collapse .main-sidebar {
-webkit-transform:translate(0, 0);
-ms-transform:translate(0, 0);
-o-transform:translate(0, 0);
transform:translate(0, 0);
width:50px !important;
z-index:850
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li {
position:relative
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li>a {
margin-right:0
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span {
border-top-right-radius:4px
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li:not(.treeview)>a>span {
border-bottom-right-radius:4px
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li>.treeview-menu {
padding-top:5px;
padding-bottom:5px;
border-bottom-right-radius:4px
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right), .sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
display:block !important;
position:absolute;
width:180px;
left:50px
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span {
top:0;
margin-left:-3px;
padding:12px 5px 12px 20px;
background-color:inherit
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>.pull-right-container {
position:relative!important;
float:right;
width:auto!important;
left:180px !important;
top:-22px !important;
z-index:900
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>.pull-right-container>.label:not(:first-of-type) {
display:none
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
top:44px;
margin-left:0
}
.sidebar-mini.sidebar-collapse .main-sidebar .user-panel>.info, .sidebar-mini.sidebar-collapse .sidebar-form, .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span, .sidebar-mini.sidebar-collapse .sidebar-menu>li>.treeview-menu, .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>.pull-right, .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
display:none !important;
-webkit-transform:translateZ(0)
}
.sidebar-mini.sidebar-collapse .main-header .logo {
width:50px
}
.sidebar-mini.sidebar-collapse .main-header .logo>.logo-mini {
display:block;
margin-left:-15px;
margin-right:-15px;
font-size:18px
}
.sidebar-mini.sidebar-collapse .main-header .logo>.logo-lg {
display:none
}
.sidebar-mini.sidebar-collapse .main-header .navbar {
margin-left:50px
}
}
.sidebar-menu, .main-sidebar .user-panel, .sidebar-menu>li.header {
	white-space:nowrap;
	overflow:hidden
}
.sidebar-menu:hover {
	overflow:visible
}
.sidebar-form, .sidebar-menu>li.header {
	overflow:hidden;
	text-overflow:clip
}
.sidebar-menu li>a {
	position:relative
}
.sidebar-menu li>a>.pull-right-container {
	position:absolute;
	right:10px;
	top:50%;
	margin-top:-7px
}
.control-sidebar-bg {
	position:fixed;
	z-index:1000;
	bottom:0
}
.control-sidebar-bg, .control-sidebar {
	top:0;
	right:-230px;
	width:230px;
-webkit-transition:right .3s ease-in-out;
-o-transition:right .3s ease-in-out;
transition:right .3s ease-in-out
}
.control-sidebar {
	position:absolute;
	padding-top:50px;
	z-index:1010
}
@media (max-width:768px) {
.control-sidebar {
padding-top:100px
}
}
.control-sidebar>.tab-content {
	padding:10px 15px
}
.control-sidebar.control-sidebar-open, .control-sidebar.control-sidebar-open+.control-sidebar-bg {
	right:0
}
.control-sidebar-open .control-sidebar-bg, .control-sidebar-open .control-sidebar {
	right:0
}
@media (min-width:768px) {
.control-sidebar-open .content-wrapper, .control-sidebar-open .right-side, .control-sidebar-open .main-footer {
margin-right:230px
}
}
.nav-tabs.control-sidebar-tabs>li:first-of-type>a, .nav-tabs.control-sidebar-tabs>li:first-of-type>a:hover, .nav-tabs.control-sidebar-tabs>li:first-of-type>a:focus {
	border-left-width:0
}
.nav-tabs.control-sidebar-tabs>li>a {
	border-radius:0
}
.nav-tabs.control-sidebar-tabs>li>a, .nav-tabs.control-sidebar-tabs>li>a:hover {
	border-top:none;
	border-right:none;
	border-left:1px solid transparent;
	border-bottom:1px solid transparent
}
.nav-tabs.control-sidebar-tabs>li>a .icon {
	font-size:16px
}
.nav-tabs.control-sidebar-tabs>li.active>a, .nav-tabs.control-sidebar-tabs>li.active>a:hover, .nav-tabs.control-sidebar-tabs>li.active>a:focus, .nav-tabs.control-sidebar-tabs>li.active>a:active {
	border-top:none;
	border-right:none;
	border-bottom:none
}
@media (max-width:768px) {
.nav-tabs.control-sidebar-tabs {
display:table
}
.nav-tabs.control-sidebar-tabs>li {
display:table-cell
}
}
.control-sidebar-heading {
	font-weight:400;
	font-size:16px;
	padding:10px 0;
	margin-bottom:10px
}
.control-sidebar-subheading {
	display:block;
	font-weight:400;
	font-size:14px
}
.control-sidebar-menu {
	list-style:none;
	padding:0;
	margin:0 -15px
}
.control-sidebar-menu>li>a {
	display:block;
	padding:10px 15px
}
.control-sidebar-menu>li>a:before, .control-sidebar-menu>li>a:after {
	content:" ";
	display:table
}
.control-sidebar-menu>li>a:after {
	clear:both
}
.control-sidebar-menu>li>a>.control-sidebar-subheading {
	margin-top:0
}
.control-sidebar-menu .menu-icon {
	float:left;
	width:35px;
	height:35px;
	border-radius:50%;
	text-align:center;
	line-height:35px
}
.control-sidebar-menu .menu-info {
	margin-left:45px;
	margin-top:3px
}
.control-sidebar-menu .menu-info>.control-sidebar-subheading {
	margin:0
}
.control-sidebar-menu .menu-info>p {
	margin:0;
	font-size:11px
}
.control-sidebar-menu .progress {
	margin:0
}
.control-sidebar-dark {
	color:#b8c7ce
}
.control-sidebar-dark, .control-sidebar-dark+.control-sidebar-bg {
	background:#222d32
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs {
	border-bottom:#1c2529
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a {
	background:#181f23;
	color:#b8c7ce
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:hover, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:focus {
	border-left-color:#141a1d;
	border-bottom-color:#141a1d
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:hover, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:focus, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:active {
	background:#1c2529
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:hover {
	color:#fff
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li.active>a, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li.active>a:hover, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li.active>a:focus, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li.active>a:active {
	background:#222d32;
	color:#fff
}
.control-sidebar-dark .control-sidebar-heading, .control-sidebar-dark .control-sidebar-subheading {
	color:#fff
}
.control-sidebar-dark .control-sidebar-menu>li>a:hover {
	background:#1e282c
}
.control-sidebar-dark .control-sidebar-menu>li>a .menu-info>p {
	color:#b8c7ce
}
.control-sidebar-light {
	color:#5e5e5e
}
.control-sidebar-light, .control-sidebar-light+.control-sidebar-bg {
	background:#f9fafc;
	border-left:1px solid #d2d6de
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs {
	border-bottom:#d2d6de
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a {
	background:#e8ecf4;
	color:#444
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:hover, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:focus {
	border-left-color:#d2d6de;
	border-bottom-color:#d2d6de
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:hover, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:focus, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:active {
	background:#eff1f7
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs>li.active>a, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li.active>a:hover, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li.active>a:focus, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li.active>a:active {
	background:#f9fafc;
	color:#111
}
.control-sidebar-light .control-sidebar-heading, .control-sidebar-light .control-sidebar-subheading {
	color:#111
}
.control-sidebar-light .control-sidebar-menu {
	margin-left:-14px
}
.control-sidebar-light .control-sidebar-menu>li>a:hover {
	background:#f4f4f5
}
.control-sidebar-light .control-sidebar-menu>li>a .menu-info>p {
	color:#5e5e5e
}
.dropdown-menu {
	box-shadow:none;
	border-color:#eee
}
.dropdown-menu>li>a {
	color:#777
}
.dropdown-menu>li>a>.glyphicon, .dropdown-menu>li>a>.fa, .dropdown-menu>li>a>.ion {
	margin-right:10px
}
.dropdown-menu>li>a:hover {
	background-color:#e1e3e9;
	color:#333
}
.dropdown-menu>.divider {
	background-color:#eee
}
.navbar-nav>.notifications-menu>.dropdown-menu, .navbar-nav>.messages-menu>.dropdown-menu, .navbar-nav>.tasks-menu>.dropdown-menu {
	width:280px;
	padding:0 0 0 0;
	margin:0;
	top:100%
}
.navbar-nav>.notifications-menu>.dropdown-menu>li, .navbar-nav>.messages-menu>.dropdown-menu>li, .navbar-nav>.tasks-menu>.dropdown-menu>li {
	position:relative
}
.navbar-nav>.notifications-menu>.dropdown-menu>li.header, .navbar-nav>.messages-menu>.dropdown-menu>li.header, .navbar-nav>.tasks-menu>.dropdown-menu>li.header {
	border-top-left-radius:4px;
	border-top-right-radius:4px;
	border-bottom-right-radius:0;
	border-bottom-left-radius:0;
	background-color:#ffffff;
	padding:7px 10px;
	border-bottom:1px solid #f4f4f4;
	color:#444444;
	font-size:14px
}
.navbar-nav>.notifications-menu>.dropdown-menu>li.footer>a, .navbar-nav>.messages-menu>.dropdown-menu>li.footer>a, .navbar-nav>.tasks-menu>.dropdown-menu>li.footer>a {
	border-top-left-radius:0;
	border-top-right-radius:0;
	border-bottom-right-radius:4px;
	border-bottom-left-radius:4px;
	font-size:12px;
	background-color:#fff;
	padding:7px 10px;
	border-bottom:1px solid #eeeeee;
	color:#444 !important;
	text-align:center
}
@media (max-width:991px) {
.navbar-nav>.notifications-menu>.dropdown-menu>li.footer>a, .navbar-nav>.messages-menu>.dropdown-menu>li.footer>a, .navbar-nav>.tasks-menu>.dropdown-menu>li.footer>a {
background:#fff !important;
color:#444 !important
}
}
.navbar-nav>.notifications-menu>.dropdown-menu>li.footer>a:hover, .navbar-nav>.messages-menu>.dropdown-menu>li.footer>a:hover, .navbar-nav>.tasks-menu>.dropdown-menu>li.footer>a:hover {
	text-decoration:none;
	font-weight:normal
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu, .navbar-nav>.messages-menu>.dropdown-menu>li .menu, .navbar-nav>.tasks-menu>.dropdown-menu>li .menu {
	max-height:200px;
	margin:0;
	padding:0;
	list-style:none;
	overflow-x:hidden
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a, .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a, .navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a {
	display:block;
	white-space:nowrap;
	border-bottom:1px solid #f4f4f4
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a:hover, .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:hover, .navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a:hover {
	background:#f4f4f4;
	text-decoration:none
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a {
	color:#444444;
	overflow:hidden;
	text-overflow:ellipsis;
	padding:10px
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a>.glyphicon, .navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a>.fa, .navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a>.ion {
	width:20px
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a {
	margin:0;
	padding:10px 10px
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>div>img {
	margin:auto 10px auto auto;
	width:40px;
	height:40px
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>h4 {
	padding:0;
	margin:0 0 0 45px;
	color:#444444;
	font-size:15px;
	position:relative
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>h4>small {
	color:#999999;
	font-size:10px;
	position:absolute;
	top:0;
	right:0
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>p {
	margin:0 0 0 45px;
	font-size:12px;
	color:#888888
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:before, .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:after {
	content:" ";
	display:table
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:after {
	clear:both
}
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a {
	padding:10px
}
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a>h3 {
	font-size:14px;
	padding:0;
	margin:0 0 10px 0;
	color:#666666
}
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a>.progress {
	padding:0;
	margin:0
}
.navbar-nav>.user-menu>.dropdown-menu {
	border-top-right-radius:0;
	border-top-left-radius:0;
	padding:1px 0 0 0;
	border-top-width:0;
	width:280px
}
.navbar-nav>.user-menu>.dropdown-menu, .navbar-nav>.user-menu>.dropdown-menu>.user-body {
	border-bottom-right-radius:4px;
	border-bottom-left-radius:4px
}
.navbar-nav>.user-menu>.dropdown-menu>li.user-header {
	height:175px;
	padding:10px;
	text-align:center
}
.navbar-nav>.user-menu>.dropdown-menu>li.user-header>img {
	z-index:5;
	height:90px;
	width:90px;
	border:3px solid;
	border-color:transparent;
	border-color:rgba(255, 255, 255, 0.2)
}
.navbar-nav>.user-menu>.dropdown-menu>li.user-header>p {
	z-index:5;
	color:#fff;
	color:rgba(255, 255, 255, 0.8);
	font-size:17px;
	margin-top:10px
}
.navbar-nav>.user-menu>.dropdown-menu>li.user-header>p>small {
	display:block;
	font-size:12px
}
.navbar-nav>.user-menu>.dropdown-menu>.user-body {
	padding:15px;
	border-bottom:1px solid #f4f4f4;
	border-top:1px solid #dddddd
}
.navbar-nav>.user-menu>.dropdown-menu>.user-body:before, .navbar-nav>.user-menu>.dropdown-menu>.user-body:after {
	content:" ";
	display:table
}
.navbar-nav>.user-menu>.dropdown-menu>.user-body:after {
	clear:both
}
.navbar-nav>.user-menu>.dropdown-menu>.user-body a {
	color:#444 !important
}
@media (max-width:991px) {
.navbar-nav>.user-menu>.dropdown-menu>.user-body a {
background:#fff !important;
color:#444 !important
}
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer {
	background-color:#f9f9f9;
	padding:10px
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer:before, .navbar-nav>.user-menu>.dropdown-menu>.user-footer:after {
	content:" ";
	display:table
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer:after {
	clear:both
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer .btn-default {
	color:#666666
}
@media (max-width:991px) {
.navbar-nav>.user-menu>.dropdown-menu>.user-footer .btn-default:hover {
background-color:#f9f9f9
}
}
.navbar-nav>.user-menu .user-image {
	float:left;
	width:25px;
	height:25px;
	border-radius:50%;
	margin-right:10px;
	margin-top:-2px
}
@media (max-width:767px) {
.navbar-nav>.user-menu .user-image {
float:none;
margin-right:0;
margin-top:-8px;
line-height:10px
}
}
.open:not(.dropup)>.animated-dropdown-menu {
backface-visibility:visible !important;
-webkit-animation:flipInX .7s both;
-o-animation:flipInX .7s both;
animation:flipInX .7s both
}
@keyframes flipInX {
0% {
transform:perspective(400px) rotate3d(1, 0, 0, 90deg);
transition-timing-function:ease-in;
opacity:0
}
40% {
transform:perspective(400px) rotate3d(1, 0, 0, -20deg);
transition-timing-function:ease-in
}
60% {
transform:perspective(400px) rotate3d(1, 0, 0, 10deg);
opacity:1
}
80% {
transform:perspective(400px) rotate3d(1, 0, 0, -5deg)
}
100% {
transform:perspective(400px)
}
}
@-webkit-keyframes flipInX {
0% {
-webkit-transform:perspective(400px) rotate3d(1, 0, 0, 90deg);
-webkit-transition-timing-function:ease-in;
opacity:0
}
40% {
-webkit-transform:perspective(400px) rotate3d(1, 0, 0, -20deg);
-webkit-transition-timing-function:ease-in
}
60% {
-webkit-transform:perspective(400px) rotate3d(1, 0, 0, 10deg);
opacity:1
}
80% {
-webkit-transform:perspective(400px) rotate3d(1, 0, 0, -5deg)
}
100% {
-webkit-transform:perspective(400px)
}
}
.navbar-custom-menu>.navbar-nav>li {
	position:relative
}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu {
	position:absolute;
	right:0;
	left:auto
}
@media (max-width:991px) {
.navbar-custom-menu>.navbar-nav {
float:right
}
.navbar-custom-menu>.navbar-nav>li {
position:static
}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu {
position:absolute;
right:5%;
left:auto;
border:1px solid #ddd;
background:#fff
}
}
.form-control {
	border-radius:0;
	box-shadow:none;
	border-color:#d2d6de
}
.form-control:focus {
	border-color:#3c8dbc;
	box-shadow:none
}
.form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control::-webkit-input-placeholder {
color:#bbb;
opacity:1
}
.form-control:not(select) {
-webkit-appearance:none;
-moz-appearance:none;
appearance:none
}
.form-group.has-success label {
	color:#00a65a
}
.form-group.has-success .form-control, .form-group.has-success .input-group-addon {
	border-color:#00a65a;
	box-shadow:none
}
.form-group.has-success .help-block {
	color:#00a65a
}
.form-group.has-warning label {
	color:#f39c12
}
.form-group.has-warning .form-control, .form-group.has-warning .input-group-addon {
	border-color:#f39c12;
	box-shadow:none
}
.form-group.has-warning .help-block {
	color:#f39c12
}
.form-group.has-error label {
	color:#dd4b39
}
.form-group.has-error .form-control, .form-group.has-error .input-group-addon {
	border-color:#dd4b39;
	box-shadow:none
}
.form-group.has-error .help-block {
	color:#dd4b39
}
.input-group .input-group-addon {
	border-radius:0;
	border-color:#d2d6de;
	background-color:#fff
}
.btn-group-vertical .btn.btn-flat:first-of-type, .btn-group-vertical .btn.btn-flat:last-of-type {
	border-radius:0
}
.icheck>label {
	padding-left:0
}
.form-control-feedback.fa {
	line-height:34px
}

.nav>li>a:hover, .nav>li>a:active, .nav>li>a:focus {
	color:#444;
	background:#f7f7f7
}
.nav-pills>li>a {
	border-radius:0;
	border-top:3px solid transparent;
	color:#444
}
.nav-pills>li>a>.fa, .nav-pills>li>a>.glyphicon, .nav-pills>li>a>.ion {
	margin-right:5px
}
.nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {
	border-top-color:#3c8dbc
}
.nav-pills>li.active>a {
	font-weight:600
}
.nav-stacked>li>a {
	border-radius:0;
	border-top:0;
	border-left:3px solid transparent;
	color:#444
}
.nav-stacked>li.active>a, .nav-stacked>li.active>a:hover {
	background:transparent;
	color:#444;
	border-top:0;
	border-left-color:#3c8dbc
}
.nav-stacked>li.header {
	border-bottom:1px solid #ddd;
	color:#777;
	margin-bottom:10px;
	padding:5px 10px;
	text-transform:uppercase
}
.nav-tabs-custom {
	margin-bottom:20px;
	background:#fff;
  min-height:450px;
	box-shadow:0 1px 1px rgba(0, 0, 0, 0.1);
	border-radius:3px
}
.nav-tabs-custom>.nav-tabs {
	margin:0;
	border-bottom-color:#f4f4f4;
	border-top-right-radius:3px;
	border-top-left-radius:3px
}
.nav-tabs-custom>.nav-tabs>li {
	border-top:3px solid transparent;
	margin-bottom:-2px;
	margin-right:5px
}
.nav-tabs-custom>.nav-tabs>li>a {
	color:#444;
	border-radius:0
}
.nav-tabs-custom>.nav-tabs>li>a.text-muted {
	color:#999
}
.nav-tabs-custom>.nav-tabs>li>a, .nav-tabs-custom>.nav-tabs>li>a:hover {
	background:transparent;
	margin:0
}
.nav-tabs-custom>.nav-tabs>li>a:hover {
	color:#999
}
.nav-tabs-custom>.nav-tabs>li:not(.active)>a:hover, .nav-tabs-custom>.nav-tabs>li:not(.active)>a:focus, .nav-tabs-custom>.nav-tabs>li:not(.active)>a:active {
border-color:transparent
}
.nav-tabs-custom>.nav-tabs>li.active {
	border-top-color:#3c8dbc
}
.nav-tabs-custom>.nav-tabs>li.active>a, .nav-tabs-custom>.nav-tabs>li.active:hover>a {
	background-color:#fff;
	color:#444
}
.nav-tabs-custom>.nav-tabs>li.active>a {
	border-top-color:transparent;
	border-left-color:#f4f4f4;
	border-right-color:#f4f4f4
}
.nav-tabs-custom>.nav-tabs>li:first-of-type {
	margin-left:0
}
.nav-tabs-custom>.nav-tabs>li:first-of-type.active>a {
	border-left-color:transparent
}
.nav-tabs-custom>.nav-tabs.pull-right {
	float:none !important
}
.nav-tabs-custom>.nav-tabs.pull-right>li {
	float:right
}
.nav-tabs-custom>.nav-tabs.pull-right>li:first-of-type {
	margin-right:0
}
.nav-tabs-custom>.nav-tabs.pull-right>li:first-of-type>a {
	border-left-width:1px
}
.nav-tabs-custom>.nav-tabs.pull-right>li:first-of-type.active>a {
	border-left-color:#f4f4f4;
	border-right-color:transparent
}
.nav-tabs-custom>.nav-tabs>li.header {
	line-height:35px;
	padding:0 10px;
	font-size:20px;
	color:#444
}
.nav-tabs-custom>.nav-tabs>li.header>.fa, .nav-tabs-custom>.nav-tabs>li.header>.glyphicon, .nav-tabs-custom>.nav-tabs>li.header>.ion {
	margin-right:5px
}
.nav-tabs-custom>.tab-content {
	background:#fff;
	padding:10px;
	border-bottom-right-radius:3px;
	border-bottom-left-radius:3px
}
.nav-tabs-custom .dropdown.open>a:active, .nav-tabs-custom .dropdown.open>a:focus {
	background:transparent;
	color:#999
}
.nav-tabs-custom.tab-primary>.nav-tabs>li.active {
	border-top-color:#3c8dbc
}
.nav-tabs-custom.tab-info>.nav-tabs>li.active {
	border-top-color:#00c0ef
}
.nav-tabs-custom.tab-danger>.nav-tabs>li.active {
	border-top-color:#dd4b39
}
.nav-tabs-custom.tab-warning>.nav-tabs>li.active {
	border-top-color:#f39c12
}
.nav-tabs-custom.tab-success>.nav-tabs>li.active {
	border-top-color:#00a65a
}
.nav-tabs-custom.tab-default>.nav-tabs>li.active {
	border-top-color:#d2d6de
}
.pagination>li>a {
	background:#fafafa;
	color:#666
}
.pagination.pagination-flat>li>a {
	border-radius:0 !important
}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
	border-top:1px solid #f4f4f4
}
.table>thead>tr>th {
	border-bottom:2px solid #f4f4f4
}
.table tr td .progress {
	margin-top:5px
}
.table-bordered {
	border:1px solid #f4f4f4
}
.table-bordered>thead>tr>th, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>tbody>tr>td, .table-bordered>tfoot>tr>td {
	border:1px solid #f4f4f4
}
.table-bordered>thead>tr>th, .table-bordered>thead>tr>td {
	border-bottom-width:2px
}
.table.no-border, .table.no-border td, .table.no-border th {
	border:0
}
table.text-center, table.text-center td, table.text-center th {
	text-align:center
}
.table.align th {
	text-align:left
}
.table.align td {
	text-align:right
}
.table-responsive {
	overflow:auto
}
.table-responsive>.table tr th, .table-responsive>.table tr td {
	white-space:normal !important
}

